/* set background entire color to a linear gradient */
body {
  background: linear-gradient(to bottom, #33ccff 0%, #D05FC9 110%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
  
.App {
  text-align: center;
}
  
/* add css for element with id madeline */
#name {
  font-size: 68px;
  text-align: center;
  color: rgb(208, 95, 201);
}

#taylor-div {
  margin: 0 auto;
}

/* media query for screens smaller than 768px (laptop and below) */
@media only screen and (max-width: 768px) {
  #name {
    font-size: 68px;
  }
}
  
/* media query for screens smaller than 425px (mobile) */
@media only screen and (max-width: 425px) {
  #name {
    font-size: 54px;
  }
}

@media only screen and (max-width: 425px) {
  #taylor{
    width: 100%;
  }
}


